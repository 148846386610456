<template>
  <div id="placement_test">
    <section
      class="page-header"
      style="background-image: url('/imgs/placement-test-01.png')"
    >
      <div class="caption">
        <i class="isologo"></i>
        <h1>{{ $lang("title") }}</h1>
      </div>
    </section>

    <section class="bg-tertiary py-6 text-center text-white decoration-3">
      <div class="container"><p v-html="$lang('desc')" /></div>
    </section>

    <section class="section-14 py-6">
      <div class="container">
        <div class="row text-center">
          <div class="col-12">
            <h4 class="text-primary">
              {{ $lang("instructions.desc") }}
            </h4>
          </div>
          <div class="col-8 offset-2 py-6">
            <i class="isologo"></i>
            <h2 class="mb-5">{{ $lang("instructions.title") }}</h2>

            <div class="card card-medium-tint p-4">
              <div class="card-body text-left">
                <ul class="disc-style text-dark-tint mb-0 disc-primary">
                  <li
                    class="d-block"
                    v-for="(item, i) of $lang('instructions.items')"
                    :key="i"
                  >
                    {{ item }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-12">
            <h4 class="mb-5 text-primary">{{ $lang("thanks") }}</h4>
            <a
              :href="$lang('link')"
              target="_blank"
              class="btn btn-primary d-inline-block"
            >
              {{ $lang("button") }}
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
